<template>
  <div class="news">
    <header>
      <span :class="{ button: true, active: activeIndex === 2 }" @click="handleSelect(2)">{{ $t('eventNews') }}</span>
      <span :class="{ button: true, active: activeIndex === 3 }" @click="handleSelect(3)">{{ $t('awardCollection') }}</span>
    </header>
    <Content :bannerList="bannerList" />
  </div>
</template>

<script>
// import NewsInfo from "./news-info";
// import PreviousWorks from "./previous-works";
import Content from '@/pages/content';
import ApiService from '@/api/apiService';
import API from '@/api';
import { LanguageEnum, Language } from '@/utils/constants';

export default {
  name: 'news',
  components: {
    Content
    // NewsInfo,
    // PreviousWorks,
  },
  props: {},
  data() {
    return {
      activeIndex: 2,
      bannerList: []
    };
  },
  watch: {
    '$store.state.isChinese'() {
      this.getBannerList(this.activeIndex);
    }
  },
  mounted() {},
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.getBannerList(2);
    });
  },
  methods: {
    async getBannerList(businessType) {
      const result = await ApiService.post(API.homePageList, {
        language: LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN],
        businessType
      });
      this.bannerList = result?.data?.desc || [];
    },
    handleSelect(key) {
      this.activeIndex = key;
      this.getBannerList(key);
    }
  }
};
</script>

<style scoped lang="less">
.news {
  margin: 0 0px 2.8vw;
  header {
    margin: 1.4vw 0px;
    text-align: left;
    .button {
      padding: 0.277vw 0.7vw;
      margin-right: 6px;
      cursor: pointer;
      font-size: 1vw;
      background: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      &:hover {
        background-color: unset;
        color: #1a2043;
        border: 1px solid #1a2043;
      }
      &.active {
        background-color: unset;
        color: #1a2043;
        border: 1px solid #1a2043;
      }
    }
  }
}
</style>
